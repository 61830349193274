body,
p {
  margin: 0;
  padding: 0;
}

p {
  overflow: hidden;
  text-overflow: ellipsis;
}

body {
  background-color: #fafafa;
  font-family: '"Helvetica Neue"';
}

a {
  color: inherit;
  text-decoration: inherit;
}

.App {
  position: relative;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.clampOnLine2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.textwrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
} */

form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

.GridItemEven,
.GridItemOdd {
  display: flex;
  align-items: center;
  justify-content: center;
}
